import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformDays'
})
export class TransformDaysPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const days: number = Math.floor(value / 24 / 60 / 60);

    return days;
  }
}

@Pipe({
  name: 'transformHours'
})
export class TransformHoursPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const hours: number = Math.floor((value % (24 * 60 * 60)) / 60 / 60);

    return hours;
  }
}

@Pipe({
  name: 'transformMinutes'
})
export class TransformMinutesPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const minutes: number = Math.floor(value % (60 * 60) / 60);

    return minutes;
  }
}

@Pipe({
  name: 'transformSeconds'
})
export class TransformSecondsPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const seconds: number = value % 60;

    return seconds;
  }
}