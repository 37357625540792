import { Component, Input } from '@angular/core';
import { OrderSummary } from 'src/app/shared/models/order.model';

@Component({
  selector: 'app-qm-payment-details',
  templateUrl: './qm-payment-details.component.html',
  styleUrls: ['./qm-payment-details.component.scss']
})
export class QmPaymentDetailsComponent {

  @Input() orderSummary: OrderSummary;
  @Input() isMobile: boolean; // optional, default is false
  @Input() cashPaid: boolean; // optional, default is false

  constructor() {
    this.orderSummary = new OrderSummary();
    this.isMobile = false;
    this.cashPaid = false;
  }
}
