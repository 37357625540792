import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SsoLoginComponent } from './components/pages/sso-login/sso-login.component';

const desktopRoutes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./components/pages/desktop-pages/desktop-pages.module').then(d => d.DesktopPagesModule)
  },
  { path: '**', redirectTo: '' },
];

const mobileRoutes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./components/pages/mobile-pages/mobile-pages.module').then(m => m.MobilePagesModule),
  },
  { path: '**', redirectTo: '' },
];

function getRoutes() {
  //Mobile Widths
  if (window.innerWidth < 768) {
    return mobileRoutes;
  } else {
    return desktopRoutes;
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(getRoutes(), { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }