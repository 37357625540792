import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';
import { GalleriaModule } from 'primeng/galleria';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TagModule } from 'primeng/tag';
import { StepsModule } from 'primeng/steps';
import { ScrollTopModule } from 'primeng/scrolltop';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { PaginatorModule } from 'primeng/paginator';
import { ImageModule } from 'primeng/image';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { BadgeModule } from 'primeng/badge';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule  } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { TimelineModule } from 'primeng/timeline';
import { InputMaskModule } from 'primeng/inputmask';
import { RatingModule } from 'primeng/rating';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { AutoFocusModule } from 'primeng/autofocus';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TransformMinutesPipe, TransformSecondsPipe } from 'src/app/shared/pipes/countdown.pipe';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';


const PRIMENG_MODULES = [
  ButtonModule,
  InputTextModule,
  CheckboxModule,
  RadioButtonModule,
  SkeletonModule,
  CardModule,
  GalleriaModule,
  AvatarModule,
  AvatarGroupModule,
  TagModule,
  StepsModule,
  ScrollTopModule,
  DropdownModule,
  OverlayPanelModule,
  TabViewModule,
  InputNumberModule,
  SliderModule,
  PaginatorModule,
  ImageModule,
  PanelModule,
  ProgressBarModule,
  BadgeModule,
  AccordionModule,
  DialogModule,
  DynamicDialogModule,
  SidebarModule,
  ToastModule,
  TimelineModule,
  InputMaskModule,
  RatingModule,
  BreadcrumbModule,
  KeyFilterModule,
  CalendarModule,
  CarouselModule,
  AutoFocusModule,
  ProgressSpinnerModule,
  TableModule,
  InputTextareaModule,
];

@NgModule({
  declarations: [
    TransformMinutesPipe,
    TransformSecondsPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PRIMENG_MODULES
  ],
  exports: [
    ReactiveFormsModule,
    TransformMinutesPipe,
    TransformSecondsPipe,
    PRIMENG_MODULES
  ]
})
export class SharedModule {}
