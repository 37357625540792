<div *ngIf="notification" (click)="onNotificationClick()" class="border-round-2xl p-3 shadow-2 mb-2 surface-200 text-900 cursor-pointer hover:shadow-5 hover:surface-300">
  <!-- Order Notification -->
  <ng-container *ngIf="notification.type === 'order'">
    <div class="flex align-items-center">
      <img [src]="getOrderStatusIcon(notification.orderStatus)" height="20" class="img_invert">
      <span class="font-bold ml-2">{{getOrderStatusText(notification.orderStatus || '') | translate}}</span>
      <div class="ml-auto" *ngIf="notification.readStatus === 'N'">
        <div style="padding: 3px;background-color: red;border-radius: 50%;"></div>
      </div>
    </div>

    <div class="flex gap-3 py-2">
      <div class="relative">
        <img [src]="notification.orderIcon" style="width: 5.6rem;height: 5.6rem;object-fit: cover;object-position: center;">
        <div *ngIf="notification.orderItems && notification.orderItems > 1"
          class="absolute bottom-0 flex justify-content-center surface-800 w-full opacity-80">
          <span class="py-1 text-white text-sm">{{notification.orderItems}} {{'Cart.CartList.Items' | translate}}</span>
        </div>
      </div>
      <div class="flex flex-column">
        <span>{{notification.title}}</span>
        <div class="text-500" style="white-space: pre-line;" [innerHTML]="notification.content"></div>
      </div>
    </div>

    <div class="flex align-items-center text-500">
      <span>{{notification.createDate}}</span>
      <div class="ml-auto">
        <span>{{notification.createTime}}</span>
      </div>
    </div>
  </ng-container>

  <!-- Promo Notification -->
  <ng-container *ngIf="notification.type === 'promo' || notification.type === 'shoppingCart' || notification.type === 'wishlist'">
    <div class="flex align-items-center">
      <img src="./assets/images/icon/promo.svg" height="20" class="img_invert">
      <span class="font-bold ml-3">{{notification.title}}</span>
      <div class="ml-auto" *ngIf="notification.readStatus === 'N'">
        <div style="padding: 3px;background-color: red;border-radius: 50%;"></div>
      </div>
    </div>

    <div class="flex py-2">
      <div [ngClass]="isClamped ? 'display-3-line': ''" class="text-700" style="white-space: pre-line;" [innerHTML]="notification.content">
      </div>
    </div>

    <div class="flex align-items-center text-500">
      <span>{{notification.createDate}}</span>
      <div class="ml-auto">
        <span>{{notification.createTime}}</span>
      </div>
    </div>
  </ng-container>

</div>
