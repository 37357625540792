import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyQmRequest } from '../shared/models/myqm-request.model';
import { environment } from 'src/environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { Layout } from '../shared/models/layout.model';
import { Gsa } from '../shared/models/gsa.model';

@Injectable({
  providedIn: 'root'
})
export class GsaService {
  private apiUrl = environment.API_URL;

  private gsaList: Gsa[] = [];

  getGsaList() {
    return firstValueFrom(this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v2/home/layout`, {})
      .pipe(
        map((res) => {
          console.log("Loading Data");
          let layoutsData: Layout[] = res.data;
          let gsaList: Gsa[] = [];

          layoutsData.forEach((layout) => {
            if (layout.dataType === 5) {
              gsaList = layout.data;
              this.gsaList = gsaList;
            }
          });

          console.log(gsaList);
          return gsaList;
        })
      )
    )
  }

  setGsaList(gsaList: Gsa[] | undefined){
    this.gsaList = gsaList || [];
  }

  getGsaIcon(gsaRate: number) {
    let iconUrl = '';

    if (this.gsaList && this.gsaList.length > 0) {
      for (let i = 0; i < this.gsaList.length; i++) {
        if (this.gsaList[i].rate === gsaRate) {
          iconUrl = this.gsaList[i].img;
          break;
        }
      }
    }
    return iconUrl;
  }

  constructor(private http: HttpClient) { }
}
