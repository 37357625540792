<span
    [ngClass]="{
        'p-inputnumber p-component': true,
        'p-inputnumber-buttons-stacked': this.showButtons && this.buttonLayout === 'stacked',
        'p-inputnumber-buttons-horizontal': this.showButtons && this.buttonLayout === 'horizontal',
        'p-inputnumber-buttons-vertical': this.showButtons && this.buttonLayout === 'vertical'
    }"
    [ngStyle]="style"
    [class]="styleClass"
    [attr.data-pc-name]="'inputnumber'"
    [attr.data-pc-section]="'root'"
>
    <input
        pInputText
        #input
        [attr.id]="inputId"
        role="spinbutton"
        [ngClass]="'p-inputnumber-input'"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [value]="formattedValue()"
        [attr.aria-valuemin]="min"
        [attr.aria-valuemax]="max"
        [attr.aria-valuenow]="value"
        [disabled]="disabled"
        [readonly]="readonly"
        [attr.placeholder]="placeholder"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.title]="title"
        [attr.size]="size"
        [attr.name]="name"
        [attr.autocomplete]="autocomplete"
        [attr.maxlength]="maxlength"
        [attr.tabindex]="tabindex"
        [attr.aria-required]="ariaRequired"
        [attr.required]="required"
        [attr.min]="min"
        [attr.max]="max"
        inputmode="decimal"
        (input)="onUserInput($event)"
        (keydown)="onInputKeyDown($event)"
        (keypress)="onInputKeyPress($event)"
        (paste)="onPaste($event)"
        (click)="onInputClick()"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        [attr.data-pc-section]="'input'"
    />
    <ng-container *ngIf="buttonLayout != 'vertical' && showClear && value">
        <TimesIcon *ngIf="!clearIconTemplate" [ngClass]="'p-inputnumber-clear-icon'" (click)="clear()" [attr.data-pc-section]="'clearIcon'" />
        <span *ngIf="clearIconTemplate" (click)="clear()" class="p-inputnumber-clear-icon" [attr.data-pc-section]="'clearIcon'">
            <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
        </span>
    </ng-container>
    <span class="p-inputnumber-button-group" *ngIf="showButtons && buttonLayout === 'stacked'" [attr.data-pc-section]="'buttonGroup'">
        <button
            type="button"
            pButton
            [ngClass]="{ 'p-inputnumber-button p-inputnumber-button-up': true }"
            class="p-button-icon-only"
            [class]="incrementButtonClass"
            [disabled]="disabled"
            tabindex="-1"
            (mousedown)="onUpButtonMouseDown($event)"
            (mouseup)="onUpButtonMouseUp()"
            (mouseleave)="onUpButtonMouseLeave()"
            (keydown)="onUpButtonKeyDown($event)"
            (keyup)="onUpButtonKeyUp()"
            [attr.aria-hidden]="true"
            [attr.data-pc-section]="'incrementbutton'"
        >
            <span *ngIf="incrementButtonIcon" [ngClass]="incrementButtonIcon" [attr.data-pc-section]="'incrementbuttonicon'"></span>
            <ng-container *ngIf="!incrementButtonIcon">
                <AngleUpIcon *ngIf="!incrementButtonIconTemplate" [attr.data-pc-section]="'incrementbuttonicon'" />
                <ng-template *ngTemplateOutlet="incrementButtonIconTemplate"></ng-template>
            </ng-container>
        </button>
        <button
            type="button"
            pButton
            [ngClass]="{ 'p-inputnumber-button p-inputnumber-button-down': true }"
            class="p-button-icon-only"
            [class]="decrementButtonClass"
            [disabled]="disabled"
            tabindex="-1"
            [attr.aria-hidden]="true"
            (mousedown)="onDownButtonMouseDown($event)"
            (mouseup)="onDownButtonMouseUp()"
            (mouseleave)="onDownButtonMouseLeave()"
            (keydown)="onDownButtonKeyDown($event)"
            (keyup)="onDownButtonKeyUp()"
            [attr.data-pc-section]="'decrementbutton'"
        >
            <span *ngIf="decrementButtonIcon" [ngClass]="decrementButtonIcon" [attr.data-pc-section]="'decrementbuttonicon'"></span>
            <ng-container *ngIf="!decrementButtonIcon">
                <AngleDownIcon *ngIf="!decrementButtonIconTemplate" [attr.data-pc-section]="'decrementbuttonicon'" />
                <ng-template *ngTemplateOutlet="decrementButtonIconTemplate"></ng-template>
            </ng-container>
        </button>
    </span>
    <button
        *ngIf="showButtons && buttonLayout !== 'stacked'"
        type="button"
        pButton
        [ngClass]="{ 'p-inputnumber-button p-inputnumber-button-up': true }"
        [class]="incrementButtonClass"
        class="p-button-icon-only"
        [disabled]="disabled"
        tabindex="-1"
        [attr.aria-hidden]="true"
        (mousedown)="onUpButtonMouseDown($event)"
        (mouseup)="onUpButtonMouseUp()"
        (mouseleave)="onUpButtonMouseLeave()"
        (keydown)="onUpButtonKeyDown($event)"
        (keyup)="onUpButtonKeyUp()"
        [attr.data-pc-section]="'incrementbutton'"
    >
        <span *ngIf="incrementButtonIcon" [ngClass]="incrementButtonIcon" [attr.data-pc-section]="'incrementbuttonicon'"></span>
        <ng-container *ngIf="!incrementButtonIcon">
            <AngleUpIcon *ngIf="!incrementButtonIconTemplate" [attr.data-pc-section]="'incrementbuttonicon'" />
            <ng-template *ngTemplateOutlet="incrementButtonIconTemplate"></ng-template>
        </ng-container>
    </button>
    <button
        *ngIf="showButtons && buttonLayout !== 'stacked'"
        type="button"
        pButton
        [ngClass]="{ 'p-inputnumber-button p-inputnumber-button-down': true }"
        class="p-button-icon-only"
        [class]="decrementButtonClass"
        [disabled]="disabled"
        tabindex="-1"
        [attr.aria-hidden]="true"
        (mousedown)="onDownButtonMouseDown($event)"
        (mouseup)="onDownButtonMouseUp()"
        (mouseleave)="onDownButtonMouseLeave()"
        (keydown)="onDownButtonKeyDown($event)"
        (keyup)="onDownButtonKeyUp()"
        [attr.data-pc-section]="'decrementbutton'"
    >
        <span *ngIf="decrementButtonIcon" [ngClass]="decrementButtonIcon" [attr.data-pc-section]="'decrementbuttonicon'"></span>
        <ng-container *ngIf="!decrementButtonIcon">
            <AngleDownIcon *ngIf="!decrementButtonIconTemplate" [attr.data-pc-section]="'decrementbuttonicon'" />
            <ng-template *ngTemplateOutlet="decrementButtonIconTemplate"></ng-template>
        </ng-container>
    </button>
</span>