<div class="flex flex-column justify-content-center py-4">
  <span [innerHTML]="'Cart.Overweight.Text1' | translate: {totalWeight: OVER_WEIGHT_VALUE}" class="text-center"></span>
  <span class="text-center">{{ 'Cart.Overweight.Text2' | translate }}</span>

  <div class="flex justify-content-center">
    <button pButton class="btn-custom-primary mt-4 px-4 shadow-2" (click)="onBack()">
      <ng-container *ngIf="fromCart()">
        <span>{{ 'Cart.Overweight.BackToCart' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="fromProduct()">
        <span>{{ 'Cart.Overweight.BackToProduct' | translate }}</span>
      </ng-container>
    </button>
  </div>
</div>