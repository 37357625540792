import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { EncryptionService } from 'src/app/services/encryption.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private encryptionService: EncryptionService
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const ignore =
      !request.url.startsWith(environment.API_URL) ||
      typeof request.body === 'undefined' ||
      request.body === null ||
      request.body.toString() === '[object FormData]';
    //!request.url.startsWith(environment.API_URL) ||
    //request.headers.has('Content-Type');

    if (ignore) {
      return next.handle(request);
    }

    let headers = request.headers.set('Content-Type', 'application/json');
    if (this.userService.isUserLogin()) {
      headers = headers.set(
        'Authorization',
        `bearer ${this.userService.getUserToken()}`
      );
    }

    let language = localStorage.getItem('lang')
      ? localStorage.getItem('lang') === 'en'
        ? 'En'
        : 'My'
      : 'En';

    let clonedRequest;
    if (request.method === 'GET') {
      //Handling for GET requests
      let url = new URL(request.urlWithParams);
      url.searchParams.has('client')
        ? url.searchParams.set('client', 'web')
        : url.searchParams.append('client', 'web');
      url.searchParams.has('language')
        ? url.searchParams.set('language', language)
        : url.searchParams.append('language', language);
      url.searchParams.has('timestamp')
        ? url.searchParams.set('timestamp', `${Date.now()}`)
        : url.searchParams.append('timestamp', `${Date.now()}`);

      let params: any = {};
      url.searchParams.forEach((value, key) => {
        params[key] = value;
      });

      const encryptedParams = this.encryptionService.encryptData(
        JSON.stringify(params)
      );

      clonedRequest = request.clone({
        url: `${url.origin}${url.pathname}?s=${encryptedParams}`,
        headers: headers,
      });
    } else {
      //Handling for POST, PUT, DELETE requests
      let body = request.body;
      if (body) {
        body = {
          ...body,
          client: 'web',
          language: language,
          timestamp: Date.now(),
        };
        body = this.encryptionService.encryptData(JSON.stringify(body));
      }
      clonedRequest = request.clone({
        headers: headers,
        body: body,
      });
    }

    return next.handle(clonedRequest).pipe(
      tap((res: any) => {
        if (res instanceof HttpResponse) {
          //Handle Unauthorized Error
          if (res.body.code === 401) {
            this.userService.logout();
            console.log('unauthorized request');
            //throw new Error('unauthorized');
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        /*this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: 'System Error',
          detail: err.message,
        });*/
        throw err;
      })
    );
  }
}
