import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselModule } from './custom-carousel/custom-carousel.component';
import { CustomOverlayPanelModule } from './custom-overlaypanel/custom-overlaypanel.component';
import { CustomInputNumberModule } from './custom-inputnumber/custom-inputnumber.component';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CustomCarouselModule,
    CustomOverlayPanelModule,
    CustomInputNumberModule,
  ],
  exports: [
    CustomCarouselModule,
    CustomOverlayPanelModule,
    CustomInputNumberModule
  ]
})
export class CustomModule { }
