<div class="pb-2 border-bottom-1" [ngClass]="isOrderDetailsPage || isOrderReceiptPage ? 'border-n50' : 'border-n800'">
    <span class="font-semibold" *ngIf="isOrderDetailsPage">{{ 'Profile.Orders.PaymentDetails' | translate }}</span>
    <div class="mt-2">
        <p-table [value]="[orderSummary]">
            <ng-template pTemplate="body">
                <tr>
                    <td class="w-full">
                        <span [ngClass]="isOrderDetailsPage || isOrderReceiptPage ? 'text-500' : ''">
                            {{ 'PaymentDetails.Subtotal' | translate }}
                            <br class="hidden md:block lg:hidden">
                            {{ 'PaymentDetails.ItemCounts' | translate: {itemCount: orderSummary.items} }}</span>
                    </td>
                    <td><b>RM</b></td>
                    <td><div><b>{{ orderSummary.subtotal | currency:"MYR":"" }}</b></div></td>
                </tr>
                <tr *ngIf="orderSummary.saved > 0">
                    <td colspan="3" [ngClass]="isMobile ? 'td-saved-mobile' : 'td-saved'">
                        <div class="flex justify-content-end">
                            <span class="text-p400 mr-2" >{{ 'PaymentDetails.Saved' | translate }}</span>
                            <span class="text-p400 font-medium">RM {{ orderSummary.saved | currency:"MYR":"" }}</span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!isShoppingCartPage">
                    <td><span [ngClass]="isOrderDetailsPage || isOrderReceiptPage ? 'text-500' : ''">{{ 'PaymentDetails.ShippingFeeSubtotal' | translate }}</span></td>
                    <td><span>RM</span></td>
                    <td><div><span>{{ orderSummary.shippingFeeTotal | currency:"MYR":"" }}</span></div></td>
                </tr>
                <tr *ngIf="!isShoppingCartPage && orderSummary.storeVoucher > 0">
                    <td><span [ngClass]="isOrderDetailsPage || isOrderReceiptPage ? 'text-500' : ''">{{ 'Voucher.StoreVoucher' | translate }}</span></td>
                    <td><span style="color: red;">- RM</span></td>
                    <td><div><span style="color: red;">{{ orderSummary.storeVoucher | currency:"MYR":"" }}</span></div></td>
                </tr>
                <tr *ngIf="!isShoppingCartPage && orderSummary.platFormVoucher > 0">
                    <td><span [ngClass]="isOrderDetailsPage || isOrderReceiptPage ? 'text-500' : ''">{{ 'Voucher.PlatformVoucher' | translate }}</span></td>
                    <td><span style="color: red;">- RM</span></td>
                    <td><div><span style="color: red;">{{ orderSummary.platFormVoucher | currency:"MYR":"" }}</span></div></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="flex pt-2">
    <div class="flex-1 flex my-auto">
        <span class="text-xl font-semibold">{{ 'PaymentDetails.Total' | translate }}</span>
    </div>
    <div class="flex-initial flex flex-column">
        <b class="text-3xl text-right">{{ orderSummary.total | currency:"MYR":"RM " }}</b>
        <span *ngIf="false" [innerHTML]="'PaymentDetails.RewardPointsReceived' | translate: {mRewardPoints: orderSummary.receivedPoints}"></span>
    </div>
</div>