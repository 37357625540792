import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyQmRequest } from '../shared/models/myqm-request.model';
import { SelectedMerchantList } from '../shared/models/merchant.model';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private apiUrl = environment.API_URL;

  private cartItems: any[] = [];
  private cartItemsSubject: BehaviorSubject<any>;
  public cartItems$: Observable<any>;

  public checkoutId: string | undefined;
  public hideCart: boolean; // hide shopping cart (PrimeNG Steps component) if checkout from 'Buy Now' button in product details page

  // used by mobile cart checkout - add / edit address
  public checkoutAddress = {
    checkoutId: '',
    selectedAddressId: 0
  };

  getCartList() {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/getList`, {})
      .pipe(
        map((res) => {
          this.flattenCartItems(res.data);
          return res.data;
        })
      );
  }

  private flattenCartItems(cartResponse: any) {
    let carts: any[] = [];
    cartResponse.forEach((merchantCart: any) => {
      carts = carts.concat(merchantCart.commodityList);
    });
    this.cartItemsSubject.next(carts);
  }

  addCart(id: number, number: number, commodityTypeList: { id: number; number: number }[]) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/add`, {
        id: id,
        number: number,
        commodityTypeList: commodityTypeList,
      })
      .pipe(
        map((res) => {
          return res.code === 200;
        })
      );
  }

  addNumber(cartCommodityId: number, number: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/addNumber`, {
        id: cartCommodityId,
        number: number,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  subNumber(cartCommodityId: number, number: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/subNumber`, {
        id: cartCommodityId,
        number: number,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  updateNumber(cartCommodityId: number, number: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/updateNumber`, {
        id: cartCommodityId,
        number: number,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  deleteCart(cartCommodityIds: number[]) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/del`, {
        ids: cartCommodityIds,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  calculatePrice(list: SelectedMerchantList) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/shopping/cart/calculatePrice`,
        list
      )
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  checkout(list: SelectedMerchantList) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/checkout`, list)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // 'Buy Now' button in product details
  checkoutBuy(id: number, number: number, commodityTypeList: { id: number; number: number }[]) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/shopping/cart/checkout/buy`, {
        id: id,
        number: number,
        commodityTypeList: commodityTypeList,
      })
      .pipe(
        map((res) => {
          if(res.code !== 200){
            throw res.msg;
          }
          return res.data;
        }),
        catchError((err) => {
          alert(err);
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  // DO NOT USE
  usePlatformPromoCode(checkoutId: string, platformPromoCode: string) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/shopping/cart/usePlatformPromoCode`,
        {
          checkoutId: checkoutId,
          platformPromoCode: platformPromoCode,
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  getCheckoutResult(checkoutId: string) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/shopping/cart/getCheckoutResult`,
        { checkoutId: checkoutId }
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  constructor(private http: HttpClient) {
    this.cartItemsSubject = new BehaviorSubject<any>(this.cartItems);
    this.cartItems$ = this.cartItemsSubject.asObservable();
    this.hideCart = false;
  }
}
