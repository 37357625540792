export enum BinaryStatus {
    'Yes' = 'Y',
    'No' = 'N'
}

export enum EditMode {
    'Add' = 1,
    'Edit' = 2
};

export enum PhoneCode {
    'MYS' = '+60'
}

export enum AddressLabel {
    'Home' = 'home',
    'Work' = 'work',
    'Others' = 'others'
}

export enum PaymentStatus {
    'Wait' = 'WAIT',
    'Success' = 'PAY_SUCCESS',
    'Failed' = 'PAY_FAIL',
    'Cancel' = 'CANCEL'
}

export enum InventoryStatus {
    'Normal' = 0,
    'Warning' = 1,
    'Soldout' = 2,
    'OnVacation' = 3,
    'NoLongerOnSale' = 4
}

export enum RefundServiceType {
    'Pickup' = 'pickUp',
    'Dropoff' = 'dropOff'
}

export enum ReturnType {
    'Refund' = 'refund',
    'Cancel' = 'cancel'
}

export enum SearchStandard {
    'MinimumCharacters' = 3,
    'MinimumMilliseconds' = 300
}

export enum ProductDescription {
    'VariationSeparator' = ', ',
    'DescriptionSeparator' = ' : '
}

export enum MerchantVoucherStatus {
    'Normal' = 0,
    'UsedUp' = 1,
    'ReachUsageLimit' = 2
}

// used to specify interval 
export enum Interval {
    'PaymentStatus' = 5000 // milliseconds
}

export enum DeliveryLogistics {
    'Merchant' = 'MERCHANT_OWN',
    'FMX' = 'FMX'
}

// /web/v1/payment/getList
export enum PayType {
    'Cash'= 'CASH',   // QM_GOLD payment method will NOT be returned if pass in this value
    'Gsa' = 'GSA',  // used for hybrid payment, when cash payment completed but not gsa payment
    'All' = ''   // pass in empty value to get all payment methods including QM_GOLD
}

export enum PaymentCompletedPayType {
    'All' = 'ALL',  // user pay fully in cash 
    'CashCompleted' = 'GSA',  // user select hybrid payment, and cash payment is completed
    'GsaCompleted' = 'BALANCE'   // user select hybrid payment, and gsa payment is completed
}

export enum PayId {
    'QmGold' = 'qm_gold'
}

export enum GsaStatus {
    'Wait' = 'WAIT',
    'Success' = 'SUCCESS',
    'Failed' = 'FAILED'
}