import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MessageService } from 'primeng/api';

import { SsoLoginComponent } from './components/pages/sso-login/sso-login.component';
/*
import { MinfiniteComponent } from './components/pages/desktop/minfinite/minfinite.component';
import { MinfinitePackageComponent } from './components/pages/desktop/minfinite/minfinite-package/minfinite-package.component';
import { MinfiniteActivationComponent } from './components/pages/desktop/minfinite-activation/minfinite-activation.component';
import { MrewardComponent } from './components/pages/desktop/mreward/mreward.component';
import { MrewardCardComponent } from './components/pages/desktop/mreward/mreward-card/mreward-card.component';
import { MrewardMembershipComponent } from './components/pages/desktop/mreward-membership/mreward-membership.component';
import { MrewardTierComponent } from './components/pages/desktop/mreward-tier/mreward-tier.component';
import { MrewardMissionComponent } from './components/pages/desktop/mreward-mission/mreward-mission.component';
import { MrewardPointHistoryComponent } from './components/pages/desktop/mreward-point-history/mreward-point-history.component';
import { MrewardHistoryComponent } from './components/pages/desktop/mreward-history/mreward-history.component';
import { MrewardHistoryVoucherComponent } from './components/pages/desktop/mreward-history/mreward-history-voucher/mreward-history-voucher.component';
import { MrewardRedemptionComponent } from './components/pages/desktop/mreward-redemption/mreward-redemption.component';
import { MrewardSearchComponent } from './components/pages/desktop/mreward-search/mreward-search.component';
import { MinfinitePurchaseHistoryComponent } from './components/pages/desktop/minfinite-purchase-history/minfinite-purchase-history.component';
import { MinfinitePurchaseHistoryDetailsComponent } from './components/pages/desktop/minfinite-purchase-history/minfinite-purchase-history-details/minfinite-purchase-history-details.component';
import { MinfinitePurchaseHistoryListComponent } from './components/pages/desktop/minfinite-purchase-history/minfinite-purchase-history-list/minfinite-purchase-history-list.component';
import { MinfinitePurchaseHistoryCardComponent } from './components/pages/desktop/minfinite-purchase-history/minfinite-purchase-history-card/minfinite-purchase-history-card.component';
import { MinfiniteCoinHistoryComponent } from './components/pages/desktop/minfinite-coin-history/minfinite-coin-history.component';
import { MinfiniteSpinComponent } from './components/pages/desktop/minfinite-spin/minfinite-spin.component';
import { MinfinitePrizeHistoryComponent } from './components/pages/desktop/minfinite-prize-history/minfinite-prize-history.component';
import { SupplyComponent } from './components/pages/desktop/supply/supply.component';
import { AboutUsComponent } from './components/pages/desktop/about-us/about-us.component';
import { PrivacyComponent } from './components/pages/desktop/privacy/privacy.component';
*/

import { CustomInterceptor } from './core/interceptors/custom.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './core/route-strategy/custom-route-strategy';
import { CustomModule } from './components/common/custom/custom.module';
import { SharedCommonModule } from './components/common/shared-common/shared-common.module';
import { GsaService } from './services/gsa.service';

@NgModule({
  declarations: [
    AppComponent,
    SsoLoginComponent,
    /*MinfiniteComponent,
    MinfinitePackageComponent,
    MinfiniteActivationComponent,
    MrewardComponent,
    MrewardCardComponent,
    MrewardMembershipComponent,
    MrewardTierComponent,
    MrewardMissionComponent,
    MrewardPointHistoryComponent,
    MrewardHistoryComponent,
    MrewardHistoryVoucherComponent,
    MrewardRedemptionComponent,
    MrewardSearchComponent,
    MinfinitePurchaseHistoryComponent,
    MinfinitePurchaseHistoryDetailsComponent,
    MinfinitePurchaseHistoryListComponent,
    MinfinitePurchaseHistoryCardComponent,
    MinfiniteCoinHistoryComponent,
    MinfiniteSpinComponent,
    MinfinitePrizeHistoryComponent,
    SupplyComponent,
    AboutUsComponent,
    PrivacyComponent,*/
  ],
  providers: [
    MessageService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    GsaService,
    { provide: APP_INITIALIZER, useFactory : configLoader, deps: [GsaService], multi: true },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    CustomModule,
    SharedCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, LocationStrategy],
      },
    }),
  ],
})
export class AppModule {}

export function configLoader(gsaService: GsaService) : () => Promise<any> {
  return async() => {
    console.log('App initialized: Get GSA Icon List from Backend');
    return gsaService.getGsaList();
  }
}

// AOT compilation support
export function HttpLoaderFactory(
  http: HttpClient,
  locationStrategy: LocationStrategy
) {
  return new TranslateHttpLoader(
    http,
    locationStrategy.getBaseHref() + './assets/i18n/',
    '.json'
  );
}
