import { AddressLabel, DeliveryLogistics, GsaStatus, ProductDescription, ReturnType } from "./enum.model";

export class Orders {
    count: number;
    list: Order[];

    constructor(orders: Orders) {
        this.count = orders.count;
        this.list = [];

        orders.list.forEach((order: Order) => {
            this.list.push(new Order(order));
        });
    }
}

export class Order {
    id: number;
    merchantName: string;
    merchantId: string;
    status: string;
    partially: boolean;
    payStatus: boolean;
    total: number;
    commodityList: OrderCommodity[];
    returnCancellationList: OrderReturnCommodity[];
    gsaMark: boolean;
    gsaStatus: GsaStatus;

    constructor(order: Order) {
        this.id = order.id;
        this.merchantName = order.merchantName;
        this.merchantId = order.merchantId;
        this.status = order.status;
        this.partially = order.partially;
        this.payStatus = order.payStatus;
        this.total = order.total;
        this.commodityList = [];
        this.returnCancellationList = [];
        this.gsaMark = order.gsaMark;
        this.gsaStatus = order.gsaStatus;

        order.commodityList.forEach((commodity: OrderCommodity) => {
            this.commodityList.push(new OrderCommodity(commodity));
        });

        order.returnCancellationList.forEach((commodity: OrderReturnCommodity) => {
            this.returnCancellationList.push(new OrderReturnCommodity(commodity));
        });
    }
}

export class OrderCommodity {
    id: number;
    commodityId: number;
    commodityTypeId: number;
    icon: string;
    name: string;
    unit: string;
    typeName: string | null;
    typeNameTwo: string | null;
    typeOption: string | null;
    typeOptionTwo: string | null;
    promo: boolean;
    price: number;
    promoPrice: number;
    number: number;
    mixedPayment: boolean;
    mixedProportion: number;
    cashAmount: number;
    gsaAmount: number;

    constructor(commodity: OrderCommodity) {
        this.id = commodity.id;
        this.commodityId = commodity.commodityId;
        this.commodityTypeId = commodity.commodityTypeId;
        this.icon = commodity.icon;
        this.name = commodity.name;
        this.unit = commodity.unit;
        this.typeName = commodity.typeName;
        this.typeNameTwo = commodity.typeNameTwo;
        this.typeOption = commodity.typeOption;
        this.typeOptionTwo = commodity.typeOptionTwo;
        this.promo = commodity.promo;
        this.price = commodity.price;
        this.promoPrice = commodity.promoPrice;
        this.number = commodity.number;
        this.mixedPayment = commodity.mixedPayment;
        this.mixedProportion = commodity.mixedProportion;
        this.cashAmount = commodity.cashAmount;
        this.gsaAmount = commodity.gsaAmount;
    }

    getProductDescription() {
        let description = '';
    
        if (this.typeName !== null && this.typeOption !== null) {
            description += this.typeName + ProductDescription.DescriptionSeparator + this.typeOption;
        }
    
        if (this.typeNameTwo !== null && this.typeOptionTwo !== null) {
            description += ProductDescription.VariationSeparator + this.typeNameTwo + ProductDescription.DescriptionSeparator + this.typeOptionTwo;
        }
    
        return description;
    }
}

// used by /web/v1/order/getList - returnCancellationList
export class OrderReturnCommodity {
    id: number; // used by /web/v1/order/refund/detail and /web/v1/order/cancel/getOrderDetail
    no: string;
    status: string;
    type: ReturnType;
    commodityList: OrderCommodity[];

    constructor(commodity: OrderReturnCommodity) {
        this.id = commodity.id;
        this.no = commodity.no;
        this.status = commodity.status;
        this.type = commodity.type;
        this.commodityList = [];

        commodity.commodityList.forEach((commodity: OrderCommodity) => {
            this.commodityList.push(new OrderCommodity(commodity));
        });
    }
}

/**
 * START - used by /web/v1/order/getReturnCancelList
 */
export class ReturnCancelOrders {
    count: number;
    list: ReturnCancelOrder[];

    constructor(orders: ReturnCancelOrders) {
        this.count = orders.count;
        this.list = [];

        orders.list.forEach((order: ReturnCancelOrder) => {
            this.list.push(new ReturnCancelOrder(order));
        });
    }
}

export class ReturnCancelOrder {
    id: number;
    merchantName: string;
    merchantId: number;
    no: string;
    status: string;
    type: ReturnType;
    commodityList: OrderCommodity[];
    
    constructor(order: ReturnCancelOrder) {
        this.id = order.id;
        this.merchantName = order.merchantName;
        this.merchantId = order.merchantId;
        this.no = order.no;
        this.status = order.status;
        this.type = order.type;
        this.commodityList = [];

        order.commodityList.forEach((commodity: OrderCommodity) => {
            this.commodityList.push(new OrderCommodity(commodity));
        });
    }
}
/**
 * END - used by /web/v1/order/getReturnCancelList
 */

export class OrderDetails {
    id: number;
    merchantName: string;
    merchantId: string;
    status: string;
    partially: boolean;
    orderNo: string;
    orderTime: string;
    orderDate: string;
    address: {
        fullName: string;
        phoneCode: string;
        phoneNumber: string;
        email: string;
        state: string;
        city: string;
        postcode: string;
        addressHere: string;
        label: AddressLabel;
    };
    paymentDetail: OrderSummary;
    commodityList: OrderCommodity[];
    shippingMethod: string; // current unused, refer to deliveryLogistics
    payMethod: string;
    payIcon: string;
    systemCancelTime: number;   // only used by TO_PAY status
    trackNo: string;    // only used by TO_RECEIVE, TO_REVIEW, RETURN_AND_CANCEL status
    returnCancellationList: OrderReturnCommodity[];
    pickUpDate: string;
    deliveredDate: string;
    expectedArrivesDateS: string;
    expectedArrivesDateE: string;
    gsaMark: boolean;
    gsaPayMethod: string;
    gsaPayIcon: string;
    gsaStatus: GsaStatus;
    deliveryLogistics: DeliveryLogistics;
    merchantLogoImage: string;

    constructor(orderDetails: OrderDetails) {
        this.id = orderDetails.id;
        this.merchantName = orderDetails.merchantName;
        this.merchantId = orderDetails.merchantId,
        this.status = orderDetails.status;
        this.partially = orderDetails.partially;
        this.orderNo = orderDetails.orderNo;
        this.orderTime = orderDetails.orderTime;
        this.orderDate = orderDetails.orderDate;
        this.address = orderDetails.address;
        this.paymentDetail = orderDetails.paymentDetail;
        this.commodityList = [];
        this.shippingMethod = orderDetails.shippingMethod;
        this.payMethod = orderDetails.payMethod;
        this.payIcon = orderDetails.payIcon;
        this.systemCancelTime = orderDetails.systemCancelTime;   // only used by TO_PAY status
        this.trackNo = orderDetails.trackNo;    // only used by TO_RECEIVE, TO_REVIEW, RETURN_AND_CANCEL status
        this.returnCancellationList = [];
        this.pickUpDate = orderDetails.pickUpDate;
        this.deliveredDate = orderDetails.deliveredDate;
        this.expectedArrivesDateS = orderDetails.expectedArrivesDateS;
        this.expectedArrivesDateE = orderDetails.expectedArrivesDateE;
        this.gsaMark = orderDetails.gsaMark;
        this.gsaPayMethod = orderDetails.gsaPayMethod;
        this.gsaPayIcon = orderDetails.gsaPayIcon;
        this.gsaStatus = orderDetails.gsaStatus
        this.deliveryLogistics = orderDetails.deliveryLogistics;
        this.merchantLogoImage = orderDetails.merchantLogoImage;

        orderDetails.commodityList.forEach((commodity: OrderCommodity) => {
            this.commodityList.push(new OrderCommodity(commodity));
        });

        orderDetails.returnCancellationList.forEach((commodity: OrderReturnCommodity) => {
            this.returnCancellationList.push(new OrderReturnCommodity(commodity));
        });
    }

    getFormattedPhoneNumber() {
        return this.address.phoneNumber.substring(0, 2) + '- ' + this.address.phoneNumber.substring(2, 5) + ' ' + this.address.phoneNumber.substring(5, this.address.phoneNumber.length);
    }
}

export class OrderSummary {
    items: number;
    total: number;
    subtotal: number;
    saved: number;
    shippingFeeTotal: number;
    storeVoucher: number;
    platFormVoucher: number;
    receivedPoints: number;
    cashAmount: number;
    gsaAmount: number;
    gsaPromoAmount: number; // Currently unused

    // used by hybrid payment, optional, default is empty string
    orderStatus: string;
    gsaStatus: string;

    constructor() {
        this.items = 0;
        this.total = 0;
        this.subtotal = 0;
        this.saved = 0;
        this.shippingFeeTotal = 0;
        this.storeVoucher = 0;
        this.platFormVoucher = 0;
        this.receivedPoints = 0;
        this.cashAmount = 0;
        this.gsaAmount = 0;
        this.gsaPromoAmount = 0;
        this.orderStatus = '';
        this.gsaStatus = '';
    }

    setOrderStatus(orderStatus: string) {
        this.orderStatus = orderStatus;
    }

    setGsaStatus(gsaStatus: string) {
        this.gsaStatus = gsaStatus;
    }
}

export const OrderSubstatusList = {
    PAY_UNSUCCESSFUL: {
        value: 'PAY_UNSUCCESSFUL',
        text: 'Profile.Orders.Substatus.PayUnsuccessful'
    },
    GSA_PAY: {
        value: 'GSA_PAY',
        text: 'Profile.Orders.Substatus.GsaPay'
    },
    TO_PAY: {
        value: 'TO_PAY',
        text: 'Profile.Orders.Substatus.ToPay'
    },
    NEW: {
        value: 'NEW',
        /* text: 'Profile.Orders.Substatus.New' */
        /* Currently always display 'Preparing' status */
        text: 'Profile.Orders.Substatus.Preparing'
    },
    PREPARING: {
        value: 'PREPARING',
        text: 'Profile.Orders.Substatus.Preparing'
    },
    /* Starting here user not allowed to cancel / return after gsa is paid */
    PACKAGE_IS_READY: {
        value: 'PACKAGE_IS_READY',
        text: 'Profile.Orders.Substatus.PackageReady'
    },
    PENDING_PICK_UP: {
        value: 'PENDING_PICK_UP',
        text: 'Profile.Orders.Substatus.PendingPickup'
    },
    PICK_UP_COMPLETE: {
        value: 'PICK_UP_COMPLETE',
        text: 'Profile.Orders.Substatus.PickupComplete'
    },
    PICK_UP_FAILED: {
        value: 'PICK_UP_FAILED',
        text: 'Profile.Orders.Substatus.PickupFailed'
    },
    PACKAGE_IS_ON_ITS_WAY: {
        value: 'PACKAGE_IS_ON_ITS_WAY',
        text: 'Profile.Orders.Substatus.PackageOnWay'
    },
    SUCCESSFULLY_DELIVERED: {
        value: 'SUCCESSFULLY_DELIVERED',
        text: 'Profile.Orders.Substatus.SuccessfulDeliver'
    },
    COMPLETE: {
        value: 'COMPLETE',
        text: 'Profile.Orders.Substatus.Completed'
    },
    REVIEWED: {
        value: 'REVIEWED',
        text: 'Profile.Orders.Substatus.Reviewed'
    },
    CANCELLED: {
        value: 'CANCELLED',
        text: 'Profile.Orders.Substatus.Cancelled'
    },
    CANCELLED_PARTIALLY: {
        value: 'CANCELLED_PARTIALLY',
        text: 'Profile.Orders.Substatus.CancelledPartially'
    },
    RETURN_REQUEST_APPROVED: {
        value: 'RETURN_REQUEST_APPROVED',
        text: 'Profile.Orders.Substatus.ReturnRequestApproved'
    },
    QUALITY_CHECK_FINISHED: {
        value: 'QUALITY_CHECK_FINISHED',
        text: 'Profile.Orders.Substatus.QualityCheckFinished'
    },
    REFUNDED: {
        value: 'REFUNDED',
        text: 'Profile.Orders.Substatus.Refunded'
    }
}

export const OrderStatusList = {
    VIEW_ALL: {
        value: "ALL",
        img: "./assets/images/status/view_all.svg",
        text: "Common.ViewAll",
        substatus: [
            OrderSubstatusList.TO_PAY,
            OrderSubstatusList.GSA_PAY,
            OrderSubstatusList.NEW,
            OrderSubstatusList.PREPARING,
            OrderSubstatusList.PACKAGE_IS_READY,
            OrderSubstatusList.PENDING_PICK_UP,
            OrderSubstatusList.PICK_UP_COMPLETE,
            OrderSubstatusList.PICK_UP_FAILED,
            OrderSubstatusList.PACKAGE_IS_ON_ITS_WAY,
            OrderSubstatusList.SUCCESSFULLY_DELIVERED,
            OrderSubstatusList.COMPLETE,
            OrderSubstatusList.REVIEWED,
            OrderSubstatusList.CANCELLED
        ]
    },
    TO_PAY: {
        value: "TO_PAY",
        img: "./assets/images/status/to_pay.svg",
        text: "Profile.Orders.Status.ToPay",
        substatus: [
            OrderSubstatusList.TO_PAY,
            OrderSubstatusList.GSA_PAY,
            OrderSubstatusList.PAY_UNSUCCESSFUL,
        ]
    },
    TO_SHIP: {
        value: "TO_SHIP",
        img: "./assets/images/status/to_ship.svg",
        text: "Profile.Orders.Status.ToShip",
        substatus: [
            OrderSubstatusList.NEW,
            OrderSubstatusList.PREPARING, 
            OrderSubstatusList.PACKAGE_IS_READY,
            OrderSubstatusList.PENDING_PICK_UP, 
            OrderSubstatusList.PICK_UP_COMPLETE, 
            OrderSubstatusList.PICK_UP_FAILED
        ]
    },
    TO_RECEIVE: {
        value: "TO_RECEIVE",
        img: "./assets/images/status/to_receive.svg",
        text: "Profile.Orders.Status.ToReceive",
        substatus: [
            OrderSubstatusList.PACKAGE_IS_ON_ITS_WAY,
            OrderSubstatusList.SUCCESSFULLY_DELIVERED
        ]
    },
    TO_REVIEW: {
        value: "TO_REVIEW",
        img: "./assets/images/status/to_review.svg",
        text: "Profile.Orders.Status.ToReview",
        substatus: [
            OrderSubstatusList.COMPLETE,
            OrderSubstatusList.REVIEWED
        ]
    },
    RETURN_AND_CANCEL: {
        value: "RETURN_CANCEL",
        img: "./assets/images/status/return.svg",
        text: "Profile.Orders.Status.ReturnAndCancel",
        substatus: [
            OrderSubstatusList.CANCELLED, 
            OrderSubstatusList.CANCELLED_PARTIALLY,
            OrderSubstatusList.RETURN_REQUEST_APPROVED,
            OrderSubstatusList.QUALITY_CHECK_FINISHED,
            OrderSubstatusList.REFUNDED,
        ]
    }
};

export const OrderCancelStatusList = {
    APPLIED_FOR_CANCELLATION: {
        value: 'APPLIED_FOR_CANCELLATION',
        text: ''
    },
    CANCELLATION_REQUEST_APPROVED: {
        value: 'CANCELLATION_REQUEST_APPROVED',
        text: ''
    },
    PENDING_REFUND: {
        value: 'PENDING_REFUND',
        text: 'Profile.Orders.CancelSubstatus.ProcessingRefund'
    },
    REFUNDED: {
        value: 'REFUNDED',
        text: 'Profile.Orders.CancelSubstatus.Refunded'
    },
    PAYMENT_NOT_SUCCESSFUL: {
        value: 'PAYMENT_NOT_SUCCESSFUL',
        text: 'Profile.Orders.CancelSubstatus.PaymentNotSuccessful'
    }
}

export const OrderRefundStatusList = {
    APPLIED_FOR_RETURN: {
        value: 'APPLIED_FOR_RETURN',
        text: 'Profile.Orders.ReturnSubstatus.ReturnRequested'
    },
    RETURN_REQUEST_APPROVED: {
        value: 'RETURN_REQUEST_APPROVED',
        text: 'Profile.Orders.ReturnSubstatus.ReturnApproved'
    },
    RETURN_REQUEST_REJECTED: {
        value: 'RETURN_REQUEST_REJECTED',
        text: 'Profile.Orders.ReturnSubstatus.ReturnRejected'
    },
    CHOOSE_RETURN_METHOD: {
        value: 'CHOOSE_RETURN_METHOD',
        text: 'Profile.Orders.ReturnSubstatus.ChooseReturnMethod'
    },
    PRINT_RETURN_LABEL_AND_RETURN: {
        value: 'PRINT_RETURN_LABEL_AND_RETURN',
        text: 'Profile.Orders.ReturnSubstatus.PrintReturnLabel'
    },
    PENDING_PICK_UP: {
        value: 'PENDING_PICK_UP',
        text: 'Profile.Orders.ReturnSubstatus.PendingPickup'
    },
    RETURN_SHIPPED: {
        value: 'RETURN_SHIPPED',
        text: 'Profile.Orders.ReturnSubstatus.ReturnShipped'
    },
    RETURN_PACKAGE_IS_IN_TRANSIT: {
        value: 'RETURN_PACKAGE_IS_IN_TRANSIT',
        text: 'Profile.Orders.ReturnSubstatus.ReturnPackageInTransit'
    },
    RETURNED_TO_SELLER: {
        value: 'RETURNED_TO_SELLER',
        text: 'Profile.Orders.ReturnSubstatus.ReturnedToSeller'
    },
    RETURN_RECEIVED_AT_WAREHOUSE: {
        value: 'RETURN_RECEIVED_AT_WAREHOUSE',
        text: 'Profile.Orders.ReturnSubstatus.WarehouseReceived'
    },
    QUALITY_CHECK_FINISHED: {
        value: 'QUALITY_CHECK_FINISHED',
        text: 'Profile.Orders.ReturnSubstatus.QualityCheck'
    },
    PENDING_REFUND: {
        value: 'PENDING_REFUND',
        text: 'Profile.Orders.ReturnSubstatus.ProcessingRefund'
    },
    REFUNDED: {
        value: 'REFUNDED',
        text: 'Profile.Orders.ReturnSubstatus.Refunded'
    },
    CANCELLED: {
        value: 'CANCELLED',
        text: 'Profile.Orders.ReturnSubstatus.ReturnCancelled'
    }
}

/**
 * START - comments-related, used by review and refund pages
 */
export class OrderCommentTag {
    tagName: string;
    selected: boolean;

    constructor(tagName: string) {
        this.tagName = tagName;
        this.selected = false;
    }
}

export class OrderComment {
    type: string;
    content: string;

    constructor(type: string, content: string) {
        this.type = type;
        this.content = content;
    }

    toJSON() {
        return {
            'type': this.type,
            'content': this.content
        };
    }
}
/**
 * END - comments-related, used by review and refund pages
 */