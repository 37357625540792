export enum BinaryStatus {
    'Yes' = 'Y',
    'No' = 'N'
}

export enum EditMode {
    'Add' = 1,
    'Edit' = 2
};

export enum PhoneCode {
    'MYS' = '+60'
}

export enum AddressLabel {
    'Home' = 'home',
    'Work' = 'work',
    'Others' = 'others'
}

export enum PaymentStatus {
    'Wait' = 'WAIT',
    'Success' = 'PAY_SUCCESS',
    'Failed' = 'PAY_FAIL',
    'Cancel' = 'CANCEL'
}

export enum InventoryStatus {
    'Normal' = 0,
    'Warning' = 1,
    'Soldout' = 2,
    'OnVacation' = 3,
    'NoLongerOnSale' = 4
}

export enum RefundServiceType {
    'Pickup' = 'pickUp',
    'Dropoff' = 'dropOff'
}

export enum ReturnType {
    'Refund' = 'refund',
    'Cancel' = 'cancel'
}

export enum SearchStandard {
    'MinimumCharacters' = 3,
    'MinimumMilliseconds' = 300
}

export enum ProductDescription {
    'VariationSeparator' = ', ',
    'DescriptionSeparator' = ' : '
}

export enum MerchantVoucherStatus {
    'Normal' = 0,
    'UsedUp' = 1,
    'ReachUsageLimit' = 2
}

// used to specify interval 
export enum Interval {
    'PaymentStatus' = 5000 // milliseconds
}