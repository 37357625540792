import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';

@Component({
  selector: 'app-overweight-card',
  templateUrl: './overweight-card.component.html',
  styleUrls: ['./overweight-card.component.scss']
})
export class OverweightCardComponent {

  readonly OVER_WEIGHT_VALUE = '30kg';

  constructor(private shoppingCartService: ShoppingCartService, private dialogRef: DynamicDialogRef) {
    
  }

  // checkout from shopping cart
  fromCart() {
    return this.shoppingCartService.hideCart === false;
  }

  // buy now button from product details page
  fromProduct() {
    return this.shoppingCartService.hideCart === true;
  }

  onBack() {
    this.dialogRef.close();
  }
}
