import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { SocialMediaListComponent } from './social-media-list/social-media-list.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoPlayerDetailsComponent } from './video-player/video-player-details/video-player-details.component';
import { OverweightCardComponent } from './overweight-card/overweight-card.component';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    NotificationCardComponent,
    PaymentDetailsComponent,
    SocialMediaListComponent,
    AudioPlayerComponent,
    VideoPlayerComponent,
    VideoPlayerDetailsComponent,
    OverweightCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
   exports: [
    LoadingSpinnerComponent,
    NotificationCardComponent,
    PaymentDetailsComponent,
    SocialMediaListComponent,
    AudioPlayerComponent,
    VideoPlayerComponent,
   ]
})
export class SharedCommonModule { }
