<div class="flex flex-column pr-1 pl-2">
  <div class="flex">
    <div class="flex-1 flex my-auto pr-2">
      <span class="font-medium">{{ 'PaymentMethod.QmGold' | translate }}</span>
    </div>
    <div class="flex-initial flex align-items-center justify-content-end pr-1">
      <img src="./assets/images/icon/qm_gold_logo.svg" [height]="isMobile ? 18 : 20" />
    </div>
  </div>
  <div class="flex">
    <div class="flex-1 flex flex-column pr-3">
      <span>{{ 'PaymentMethod.TotalToPay' | translate }}</span>
      <div class="flex">
        <span>{{ 'PaymentMethod.TotalCashPayment' | translate }}&nbsp;</span>
        <span class="text-w300" *ngIf="!cashPaid">({{ 'PaymentMethod.ToPay' | translate }})</span>
        <span *ngIf="cashPaid">({{ 'PaymentMethod.Paid' | translate }})</span>
      </div>
      <div class="flex">
        <span>{{ 'PaymentMethod.TotalGsaPayment' | translate }}&nbsp;</span>
        <span [ngClass]="cashPaid ? 'text-w300' : ''">({{ 'PaymentMethod.ToPay' | translate }})</span>
      </div>
    </div>
    <div class="flex-initial flex flex-column">
      <span class="pr-2 pl-1">RM</span>
      <span class="pr-2 pl-1" [ngClass]="!cashPaid ? 'font-semibold bg-p75' : ''">RM</span>
      <span class="pr-2 pl-1" [ngClass]="cashPaid ? 'font-semibold bg-p75' : ''">RM</span>
    </div>
    <div class="flex-initial flex flex-column text-right">
      <span class="pr-1">{{ orderSummary.total | currency:"MYR":"" }}</span>
      <span class="pr-1" [ngClass]="!cashPaid ? 'font-semibold bg-p75' : ''">{{ orderSummary.cashAmount | currency:"MYR":"" }}</span>
      <span class="pr-1" [ngClass]="cashPaid ? 'font-semibold bg-p75' : ''">{{ orderSummary.gsaAmount | currency:"MYR":"" }}</span>
    </div>
  </div>
</div>